var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "backs" },
    [
      _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "addAddress" },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "primary", prefix: "add-one" },
                  on: { click: _vm.handleAdd },
                },
                [_vm._v(" 添加工作地点 ")]
              ),
            ],
            1
          ),
          _c("custom-table", {
            attrs: {
              columns: _vm.columns,
              "data-source": _vm.dataSource,
              "row-key": "addressId",
              "table-ref": ".backs",
              pagination: {
                current: _vm.pager.pageNum,
                pageSize: _vm.pager.pageSize,
                total: _vm.total,
                showTotal: function (total) {
                  return "共 " + total + " 条数据"
                },
                showQuickJumper: true,
                showSizeChanger: true,
              },
            },
            on: { change: _vm.loadDataList },
            scopedSlots: _vm._u([
              {
                key: "operation",
                fn: function (ref) {
                  var scope = ref.scope
                  return [
                    _c(
                      "a-popover",
                      { attrs: { placement: "bottomLeft", trigger: "hover" } },
                      [
                        _c("template", { slot: "content" }, [
                          _c(
                            "div",
                            { staticClass: "more-handle-btn" },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "link" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEdit(scope.record)
                                    },
                                  },
                                },
                                [_vm._v(" 编辑 ")]
                              ),
                              _c(
                                "a-button",
                                {
                                  staticClass: "delete",
                                  attrs: { type: "link" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDel(scope.record)
                                    },
                                  },
                                },
                                [_vm._v(" 删除 ")]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "more-handle" }, [
                          _vm._v("···"),
                        ]),
                      ],
                      2
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("LocationForm", {
        ref: "inductionClassForms",
        on: { getFetchDataApiFn: _vm.getFetchDataApiFn },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
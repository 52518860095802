var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          attrs: {
            closable: true,
            visible: _vm.visible,
            title: (_vm.mode == "add" ? "添加" : "编辑") + "工作地点",
            "ok-text": "保存",
            size: "normal",
          },
          on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
        },
        [
          _c(
            "a-form-model",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-col": _vm.labelCol,
                "wrapper-col": _vm.wrapperCol,
              },
            },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "地点名称", prop: "addressName" } },
                [
                  _c("a-input", {
                    attrs: { "max-length": 30, placeholder: "最多30个字符" },
                    model: {
                      value: _vm.form.addressName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "addressName", $$v)
                      },
                      expression: "form.addressName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "所在地区", prop: "area" } },
                [
                  _c("CbSelectArea", {
                    ref: "selectArea",
                    attrs: {
                      "province-width": 100,
                      "city-width": 100,
                      "area-width": 100,
                      "auto-fill-in-area-value": false,
                    },
                    on: { getStrandValue: _vm.onChange },
                    model: {
                      value: _vm.form.area,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "area", $$v)
                      },
                      expression: "form.area",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "详细地址", prop: "detail" } },
                [
                  _c("a-input", {
                    attrs: { "max-length": 100, placeholder: "最多100个字符" },
                    model: {
                      value: _vm.form.detail,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "detail", $$v)
                      },
                      expression: "form.detail",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "备注", prop: "remarks" } },
                [
                  _c("a-textarea", {
                    attrs: {
                      placeholder: "最多300个字符",
                      "max-length": 300,
                      "auto-size": { minRows: 3, maxRows: 5 },
                    },
                    model: {
                      value: _vm.form.remarks,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remarks", $$v)
                      },
                      expression: "form.remarks",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }